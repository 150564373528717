@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
:root {
  --primary-color: #e67c2a;
  --dark-color: #341e67;
  --light-color: #f4f4f4;
}

* {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.icon-img {
  margin: auto;
  display: block;
  max-width:230px;
  max-height:95px;
  width: auto;
  height: auto;
  transition: all 200 ease-in;
}

.grey-img {
  filter: brightness(50%);
}

.grey-img:hover {
  filter: brightness(100%);
}

.white-img {
  filter: brightness(100%) !important;
}

.text-highlight-primary {
  //color: var(--primary-color);
  color: #ff9f54;
}

.showcase {
  height: calc(100vh - 150px);
  background: url("https://lq-gc-graphics.s3.amazonaws.com/banner_AT7.jpg")
    no-repeat center center/cover;
}

.showcase::after {
  content: "";
  background: rgba(34, 10, 70, 0.6);
  box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
}

.showcase-top img {
  transform: translate(-50%, -50%);
}

.showcase-top a {
  transform: translate(-50%, -50%);
}

/* Tabs */
.tab-border {
  border-bottom: var(--primary-color) 4px solid;
}

#tab-1:hover > img {
  filter: brightness(100%);
}
#tab-2:hover > img {
  filter: brightness(100%);
}
#tab-3:hover > img {
  filter: brightness(100%);
}

/* Hide initial content */

.show {
  display: block !important;
  opacity: 1 !important;
  transition: all 1000 ease-in;
}

#tab-1-content .tab-1-content-inner {
  grid-template-columns: repeat(2, 1fr);
}

#tab-2-content .tab-2-content-top {
  grid-template-columns: 2fr 1fr;
}

.table {
  border-spacing: 0;
}

.table tbody tr td:first-child {
  text-align: left;
}

.table tbody tr:nth-child(odd) {
  background: rgba(34, 10, 70, 0.6);
}

.footer {
  max-width: 75%;
}

.footer .footer-cols {
  grid-template-columns: repeat(4, 1fr);
}

/* Text Styles */

/* Buttons */
.btn {
  background: var(--primary-color);
  transition: opacity 0.2s ease-in;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);
}

@media (max-width: 960px) {
  .footer .footer-cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .showcase::after {
    background: rgba(34, 10, 70, 0.6);
    box-shadow: inset 80px 80px 150px #000000, inset -80px -80px 150px #000000;
  }

  #tab-1-content .tab-1-content-inner {
    grid-template-columns: 1fr;
  }

  #tab-2-content .tab-2-content-bottom {
    grid-template-columns: 1fr;
  }
}
